import { VaporProvider } from '@vapor/ui';
import Head from 'next/head';
import '../styles/reset.css';
import '../styles/variables.css';
import '../styles/global.css';

function MyApp({ Component, pageProps }) {
  return (
    <VaporProvider ssr>
      <Component {...pageProps} />

      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff"></meta>

        <title>Weekrise - A weekly task calendar</title>
        <meta name="title" content="Weekrise - A weekly task calendar" />
        <meta
          name="description"
          content="Weekrise is a task list organized by day of the week. This makes it easy to plan whats to do today, tomorrow, and the day after."
        />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://weekrise.com/" />
        <meta property="og:title" content="Weekrise - A weekly task calendar" />
        <meta
          property="og:description"
          content="Weekrise is a task list organized by day of the week. This makes it easy to plan whats to do today, tomorrow, and the day after."
        />
        <meta property="og:image" content="https://weekrise.com/og.png" />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://weekrise.com/" />
        <meta property="twitter:title" content="Weekrise - A weekly task calendar" />
        <meta
          property="twitter:description"
          content="Weekrise is a task list organized by day of the week. This makes it easy to plan whats to do today, tomorrow, and the day after."
        />
        <meta property="twitter:image" content="https://weekrise.com/og.png"></meta>

        {/* <link rel="stylesheet" href="/inter/index.css" /> */}

        <style>{`
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 400;
            font-display: swap;
            src: url("/inter/Inter-Regular.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-Regular.woff?v=3.19") format("woff");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 400;
            font-display: swap;
            src: url("/inter/Inter-Italic.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-Italic.woff?v=3.19") format("woff");
          }
          
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 500;
            font-display: swap;
            src: url("/inter/Inter-Medium.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-Medium.woff?v=3.19") format("woff");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 500;
            font-display: swap;
            src: url("/inter/Inter-MediumItalic.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-MediumItalic.woff?v=3.19") format("woff");
          }
          
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 600;
            font-display: swap;
            src: url("/inter/Inter-SemiBold.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-SemiBold.woff?v=3.19") format("woff");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 600;
            font-display: swap;
            src: url("/inter/Inter-SemiBoldItalic.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-SemiBoldItalic.woff?v=3.19") format("woff");
          }
          
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 700;
            font-display: swap;
            src: url("/inter/Inter-Bold.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-Bold.woff?v=3.19") format("woff");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 700;
            font-display: swap;
            src: url("/inter/Inter-BoldItalic.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-BoldItalic.woff?v=3.19") format("woff");
          }
          
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 800;
            font-display: swap;
            src: url("/inter/Inter-ExtraBold.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-ExtraBold.woff?v=3.19") format("woff");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 800;
            font-display: swap;
            src: url("/inter/Inter-ExtraBoldItalic.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-ExtraBoldItalic.woff?v=3.19") format("woff");
          }
          
          @font-face {
            font-family: 'Inter';
            font-style:  normal;
            font-weight: 900;
            font-display: swap;
            src: url("/inter/Inter-Black.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-Black.woff?v=3.19") format("woff");
          }
          @font-face {
            font-family: 'Inter';
            font-style:  italic;
            font-weight: 900;
            font-display: swap;
            src: url("/inter/Inter-BlackItalic.woff2?v=3.19") format("woff2"),
                 url("/inter/Inter-BlackItalic.woff?v=3.19") format("woff");
          }
        `}</style>
      </Head>
    </VaporProvider>
  );
}

export default MyApp;
